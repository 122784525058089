var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getWebSiteContactErrors.step),callback:function ($$v) {_vm.$set(_vm.getWebSiteContactErrors, "step", $$v)},expression:"getWebSiteContactErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getWebSiteContactErrors.stepOne
                                        .validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('webSiteContact:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('webSiteContact:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"box-autocomplete",attrs:{"label":`${_vm.$t(
                                            'webSiteContact:responsible'
                                        )}`,"item-value":"name","items":_vm.getUsersList,"return-object":"","multiple":"","filter":_vm.filter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(` ${data.item.name} ${data.item.lastname},`)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mx-2",attrs:{"size":"35","color":"secondary"}},[(
                                                            !data.item
                                                                .picture
                                                        )?_c('span',{staticClass:"white--text central__avatarFont"},[_vm._v(" "+_vm._s(_vm.avatarNick( data.item .name, data.item .lastname ))+" ")]):_c('img',{attrs:{"src":`${data.item.picture}`}})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.lastname)+" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.job)+" ")])],1)]}}]),model:{value:(
                                            _vm.getWebSiteContactModal.responsible
                                        ),callback:function ($$v) {_vm.$set(_vm.getWebSiteContactModal, "responsible", $$v)},expression:"\n                                            getWebSiteContactModal.responsible\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'webSiteContact:notes'
                                        )}`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(
                                            _vm.getWebSiteContactModal.notes
                                        ),callback:function ($$v) {_vm.$set(_vm.getWebSiteContactModal, "notes", $$v)},expression:"\n                                            getWebSiteContactModal.notes\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }