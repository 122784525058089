<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getWebSiteContactErrors.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getWebSiteContactErrors.stepOne
                                            .validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('webSiteContact:basicInfo')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('webSiteContact:basicInfo')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete
                                            :label="`${$t(
                                                'webSiteContact:responsible'
                                            )}`"
                                            item-value="name"
                                            :items="getUsersList"
                                            return-object
                                            v-model="
                                                getWebSiteContactModal.responsible
                                            "
                                            multiple
                                            class="box-autocomplete"
                                            :filter="filter"
                                        >
                                            <template v-slot:selection="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname},`
                                                    }}
                                                </div>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar>
                                                    <v-avatar
                                                        size="35"
                                                        color="secondary"
                                                        class="mx-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                !data.item
                                                                    .picture
                                                            "
                                                            class="white--text central__avatarFont"
                                                        >
                                                            {{
                                                                avatarNick(
                                                                    data.item
                                                                        .name,
                                                                    data.item
                                                                        .lastname
                                                                )
                                                            }}
                                                        </span>
                                                        <img
                                                            :src="`${data.item.picture}`"
                                                            v-else
                                                        />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <!-- === === CONTENT === === -->
                                                <!-- === USERS ===  -->
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ data.item.lastname }}
                                                        {{ data.item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.job }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t(
                                                'webSiteContact:notes'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="
                                                getWebSiteContactModal.notes
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            hideContacts: true,
            disabled: false,
            showMenu: false,
            step: 1,
            editable: true,
        }
    },
    methods: {
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
            else if (item.fullName) {
                return item.fullName
                    .toLowerCase()
                    .includes(queryText.toLowerCase())
            } else
                return item.name.toLowerCase().includes(queryText.toLowerCase())
        },
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0]
            return nick.toUpperCase()
        },
    },
    computed: {
        ...mapGetters([
            'getWebSiteContactModal',
            'getWebSiteContactErrors',
            'getUsersList',
        ]),
    }
}
</script>
