<template>
    <v-container>
        <Header
            :registryStatus="getWebSiteContactStatuses"
            :recordIndex="getWebSiteContactDetails.index"
            :registryTitle="getWebSiteContactRegistry.name"
            :registryUrl="'webSiteContact'"
            :actualStatus="getWebSiteContactDetails.status"
            :avatarData="getWebSiteContactDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('webSiteContact:edition')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editWebSiteContact()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('webSiteContact:edit') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/WebSiteContact/Details/Information.vue'
import AEContent from './../../../components/Registries/WebSiteContact/Modal/Content'
import Buttons from './../../../components/Registries/WebSiteContact/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getWebSiteContactStatuses',
            'getWebSiteContactRegistry',
            'getWebSiteContactDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setWebSiteContactDetails',
            'setWebSiteContactStatuses',
            'setWebSiteContactRegistry',
            'setWebSiteContactModal',
            'clearWebSiteContactErrors',
            'clearRepresentativesInput',
            'clearWebSiteContactModal',
        ]),
        ...mapActions([
            'fetchWebSiteContact',
            'updateWebSiteContactStatus',
            'fetchUsers',
        ]),
        async changeStatus(status) {

            const webSiteContact = await this.fetchWebSiteContact({id: this.getWebSiteContactDetails._id});
            await this.updateWebSiteContactStatus({
                status,
                id: this.getWebSiteContactDetails._id,
                webSiteContact
            })
            
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editWebSiteContact() {
            await void this.fetchUsers({
                select: 'name lastname mail status isDeleted',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearWebSiteContactErrors()
            this.setWebSiteContactModal()
        },
        closeModal() {
            this.open = false
            this.clearWebSiteContactErrors()
            this.clearRepresentativesInput()
            this.clearWebSiteContactModal()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchWebSiteContact', { id: to.params.id, next })
    },
}
</script>
