<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{
                                $get(
                                    getWebSiteContactDetails,
                                    'contractor',
                                    $t('global:empty')
                                )
                            }}
                        </p>
                        <span
                            ><b>{{ `${$t('webSiteContact:creationAt')}` }}:</b>
                            {{
                                getWebSiteContactDetails.createdAt
                                    ? $moment(
                                          getWebSiteContactDetails.createdAt
                                      ).format(`DD/MM/YYYY HH:mm`)
                                    : $t('global:empty')
                            }}</span
                        >
                        <span
                            ><b>{{ `${$t('webSiteContact:responsible')}` }}:</b>
                            {{
                               getWebSiteContactDetails.responsible&&getWebSiteContactDetails.responsible.length>0? `${getWebSiteContactDetails.responsible.map(el=>{
                                    return  ` ${$get(
                                    el,
                                    'name',
                                    ''
                                )} ${$get(
                                    el,
                                    'lastname',
                                    ''
                                )}`
                                })}`:$t('global:empty')
                                
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getWebSiteContactDetails, 'contractor', '')
                            "
                            :label="$t('webSiteContact:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getWebSiteContactDetails, 'contact', '')
                            "
                            :label="$t('webSiteContact:contact')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getWebSiteContactDetails, 'title', '')"
                            :label="$t('webSiteContact:title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="mb-15"
                        ><v-textarea
                            readonly
                            :value="
                                $get(getWebSiteContactDetails, 'content', '')
                            "
                            :label="$t('webSiteContact:content')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            :value="$get(getWebSiteContactDetails, 'notes', '')"
                            :label="$t('webSiteContact:notes')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getWebSiteContactDetails']),
    },
}
</script>
